import React, { Component } from 'react';
import { Chart } from "react-google-charts";
import { Link } from 'react-router-dom';
import { report, getAllOrders } from '../api/apiService';
import Moment from 'react-moment';
import { palyNotifyTone } from '../component/notify-tone';
import moment from 'moment';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import ReportOverView from '../all-report/reportOverView';

const mapStateToProps = (state) => {
    return {
        socketId: state.loaderReducer.socketId,
        loaderDisplay: state.loaderDisplay
    }
}
class dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oderList: [],
            reportAll: {},
            last15Orders: [],
            last15Users: []
        }
        if (this.props.socketId !== null) {
            this.initSocket();
        } else {
            setTimeout(() => {
                if (this.props.socketId !== null) {
                    this.initSocket();
                }
            }, 5000)
        }
        this.allReport();
        this.allOrders();
    }
    initSocket = () => {
        this.props.socketId.on("admin-order", (data) => {
            this.allOrders();
            palyNotifyTone()
        });
    }
    lastOrders = () => {
        const orders = [['Move', 'Orders']];
        const users = [['Move', 'Users']];
        this.state.reportAll.lastOrders.sort((a, b) => a._id > b._id ? 1 : -1).map(m => {
            let date = moment(m._id).format("MM-DD");
            let y = [date, m.amount];
            orders.push(y);
        })
        this.setState({ last15Orders: orders })

        this.state.reportAll.last15Users.sort((a, b) => a._id > b._id ? 1 : -1).map(m => {
            let date = moment(m._id).format("MM-DD");
            let y = [date, m.user];
            users.push(y);
        })
        this.setState({ last15Users: users })
    }
    // Api Call////////////////////////////////////////////
    allReport = async () => {
        store.dispatch(loader(true));
        try {
            const all = await report();
            this.setState({ reportAll: all.data.data }, () => {
                this.lastOrders();
                store.dispatch(loader(false));
            })
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }

    // Get all orders ---------
    allOrders = async () => {
        try {
            const aOrders = await getAllOrders("all", 1, 5)
            this.setState({ oderList: aOrders.data.data });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {

        return (
            <div style={{backgroundColor:'#fffff'}}>
                {Object.keys(this.state.reportAll).length === 0 ?
                    <div></div>
                    :
                    <div className="my-4 text-secondary">
                        <div className="row foods-count mb-4">
                            <div className="col-sm-3">
                                <div className="count-chart"  style={{ background: "#ece2ff", color: "#bb9bf7" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" style={{ background: "#ece2ff", color: "#bb9bf7" }}>
                                            <i className="demo-icon">&#xe821;</i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.totalCount.totalUsers}</span>
                                            <span className="m-0" style={{color:'#000'}}>Total Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#e2fffa", color: "#3ee4ff" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon">
                                            <i className="demo-icon">&#xe816;</i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.totalCount.totalOutlets}</span>
                                            <span className="m-0" style={{color:'#000'}}>Total Outlets</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#fff9db", color: "#e7df7d" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" >
                                            <i className="fas fa-shopping-basket"></i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.totalCount.totalOrders}</span>
                                            <span className="m-0" style={{color:'#000'}}>Total Orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#e8ffe2", color: "#8edd89" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" >
                                            <i className="fas fa-rupee-sign"></i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">₹{this.state.reportAll.totalCount.totalBill}</span>
                                            <span className="m-0" style={{color:'#000'}}>Total Bill</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row foods-count">
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#bcf1ed", color: "#8edd89" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" style={{ background: "#f7f7f7", color: "#f1bb4a" }}>
                                            {/* <i className="far fa-user"></i> */}
                                            <i className="demo-icon">&#xe821;</i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.today.todayUsers}</span>
                                            <span className="m-0" style={{color:'#000'}}>Today Users</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart"  style={{ background: "#e1f3a3", color: "#e7df7d" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" style={{ background: "#f7f7f7", color: "#f1bb4a" }}>
                                            <i className="demo-icon">&#xe816;</i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.today.todayOutlets}</span>
                                            <span className="m-0" style={{color:'#000'}}>Today Outlets</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#ccfad6", color: "#3ee4ff" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" style={{ background: "#f7f7f7", color: "#f1bb4a" }}>
                                            <i className="fas fa-shopping-basket"></i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">{this.state.reportAll.today.todayOrders} 
                                            <span style={{fontSize: 10, color: "#28a745"}}> ( Normal: {this.state.reportAll.today.todayOrdersNormal} </span>
                                            <span style={{fontSize: 10, color: "#d2a33b"}}> | Auction: {this.state.reportAll.today.todayOrdersAuction} )</span>
                                            </span>
                                            <span className="m-0" style={{color:'#000'}}>Today Orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="count-chart" style={{ background: "#d3d7f7", color: "#bb9bf7" }}>
                                    <div className="media-flex-center">
                                        <div className="count-icon" style={{ background: "#f7f7f7", color: "#f1bb4a" }}>
                                            <i className="fas fa-rupee-sign"></i>
                                        </div>
                                        <div className="flex-meta">
                                            <span className="m-0">₹{this.state.reportAll.today.toDayBill}</span>
                                            <span className="m-0" style={{color:'#000'}}>Today Bill</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-4 foods-count">
                            <div className="col-sm-6">
                                <div className="category-container">
                                    <div className='graph-header'>
                                        <h3>Last 15 Days Order</h3>
                                    </div>
                                    <Chart
                                        width={'100%'}
                                        height={'250px'}
                                        chartType="ComboChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.last15Orders}
                                        options={{
                                            // title: '15 Days orders',
                                            vAxis: { title: 'Amount' },
                                            hAxis: { title: 'Date' },
                                            seriesType: 'bars',
                                            colors: ['#045efa'],
                                            // series: { 5: { type: 'line' } },
                                        }}
                                    // rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6" >
                                <div className="my-1 category-container">
                                    <div className='graph-header'>
                                        <h3>Users</h3>
                                    </div>
                                    <Chart
                                        // width={'600px'}
                                        height={'250px'}
                                        borderRadius={'13px'}
                                        chartType="LineChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.last15Users}
                                        options={{
                                            // title: 'Users',
                                            hAxis: {
                                                title: 'Time',
                                            },
                                            // vAxis: {
                                            //     title: 'Popularity',
                                            // },
                                            colors: ['#045efa'],
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <ReportOverView/>
                        {/* <div className="row my-4">
                            <div className="col-12">
                                <div className="dashboard-container">
                                    <h5 className="text-secondary">All Orders</h5>
                                    <table className="table dash-table table-hover text-secondary">
                                        <thead>
                                            <tr className="text-left">
                                                <th>Date/Amount</th>
                                                <th>Order Id/Type</th>
                                                <th>User</th>
                                                <th>Outlet</th>
                                                <th>Payment Mode</th>
                                                <th>Payment Status</th>
                                                <th>Order Status</th>
                                                <th className="text-center">Delivery Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.oderList.map(item => {
                                                return <tr key={item._id} className="text-left">
                                                    <td>
                                                        <Moment format="DD-MM-YY HH:mm">
                                                            {item.createdDate}
                                                        </Moment>
                                                        <br />
                                                       <span className="font-12"> Amount <span className="font-12 badge badge-info">₹{item.orderAmount}</span></span>

                                                    </td>
                                                    <td>
                                                        <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                                                        <span className={item.orderType === 0 ? "text-success" : "text-warning"}>{item.orderType === 0 ? "Normal" : "Auction"}</span>

                                                    </td>
                                                    <td><Link to={`/users-management/user-details/${item.userId}`}>{item.user.full_name}</Link></td>
                                                    <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet?.outlet_name}</Link>
                                                    <br/>
                                                    <span className="font-12">{item.pickUpAddress.landmark}</span>
                                                    </td>
                                                    <td>{item.paymentMode}</td>
                                                    <td>
                                                        <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                                                    </td>
                                                    <td>
                                                        <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                                                            "badge-primary" : item.orderStatus === "out-for-delivery" ?
                                                                "badge-secondary" : item.orderStatus === "ready-for-pickup" ?
                                                                "badge-info" : item.orderStatus === "submitted" ?
                                                                    "badge-warning" : "badge-danger"}`}>
                                                            {item.orderStatus}
                                                        </span>
                                                    </td>
                                                    <td className="text-center">{item.deliveryType === "take_away" ? "Take away" : item.deliveryStatus}
                                                    <br/>
                                                    <span className="font-12">{item.deliveryPatner}</span>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="text-center"><Link to="/orders" className="dash-table-btn">View more</Link></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(dashboard);


