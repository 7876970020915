import React, { Component } from 'react';
import FormSetings from '../component/c-settings/formSetings';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import {getAllSettings,updateAllSettings,} from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const mapStateToProps = (store) => {
	return {
		loaderDisplay: store.loaderDisplay
	}
}

class AppSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			settingsAll: [],

			paymentOnline: true,
			cashOnDelivery: true,
			homeDelivery: true,
			takeaway: true,
			forceClose: false,
		}
		this.allSettings();

	}
	paymentOnline = (e) => {
		if (window.confirm("Do you want to change Payment Mode?")) {
			this.setState({ paymentOnline: e.target.checked }, () => {
				this.parentFunctionCall();
			});
		}
	}
	cashOnDelivery = (e) => {
		if (window.confirm("Do you want to change Cash On delivery?")) {
			this.setState({ cashOnDelivery: e.target.checked }, () => {
				this.parentFunctionCall();
			});
		}
	}
	homeDelivery = (e) => {
		if (window.confirm("Do you want to change Home Delivery?")) {
			this.setState({ homeDelivery: e.target.checked }, () => {
				this.parentFunctionCall();
			});
		}
	}
	takeaway = (e) => {
		if (window.confirm("Do you want to change Takeaway?")) {
			this.setState({ takeaway: e.target.checked }, () => {
				this.parentFunctionCall();
			});
		}
	}
	forceClose = (e) => {
		if (window.confirm(`Do you want to ${e.target.checked ? 'close' : 'open'} all outlet?`)) {
			this.setState({ forceClose: e.target.checked }, () => {
				this.parentFunctionCall();
			});
		}
	}

	/*
	* Child
	* Component to function call
	*/
	parentFunctionCall = (e) => {
		const data = {
			"paymentOnline": this.state.paymentOnline,
			"cashOnDelivery": this.state.cashOnDelivery,
			"homeDelivery": this.state.homeDelivery,
			"takeaway": this.state.takeaway,
			"forceClose": this.state.forceClose,
			"deliveryPrice": e?.delivaryCharage,
			"autoSubmit": this.state.settingsAll.autoSubmit,
			"extraAd": this.state.settingsAll.extraAd
		}
		this.updateSettings(data)
	}
	// Api call /////////////////
	/*
	* Api
	* Get All Settings
	*/
	allSettings = async () => {
		store.dispatch(loader(true));
		try {
			const settings = await getAllSettings();
			this.setState({
				settingsAll: settings.data.data,
				paymentOnline: settings.data.data.paymentMode.online,
				cashOnDelivery: settings.data.data.paymentMode.cashOnDelivery,
				homeDelivery: settings.data.data.deliveryMode.homeDelivery,
				takeaway: settings.data.data.deliveryMode.takeaway,
				forceClose: settings.data.data.forceClose,
			});
			store.dispatch(loader(false));
		} catch (ex) {
			console.log(ex);
			store.dispatch(loader(false));
		}
	}
	/*
	* Api
	* Update All Settings
	*/
	updateSettings = async (data) => {
		store.dispatch(loader(true));

		try {
			const settings = await updateAllSettings(data);
			this.allSettings();
			store.dispatch(loader(false));
			ToastSuccess("Update Settings")
		} catch (ex) {
			console.log(ex);
			store.dispatch(loader(false));
		}
	}
	render() {
		return (
			<>
				{this.state.settingsAll.length === 0 ?
					<div></div>
					:
					<div className="container-fluid">
						<div className="my-3">
							<div className="row">
								<div className="col-sm-6 mt-3">
									<FormSetings
										parentFunctionCall={this.parentFunctionCall}
										delivaryCharage={this.state.settingsAll.deliveryPrice}
									/>
									</div>
									<div className="col-sm-6 mt-3">
									<div className="category-container">
										<h5 className="text-secondary">Settings</h5>
										<div>
											<div className="form-group mb-2">
												<FormGroup>
													<FormControlLabel
														control={<Checkbox checked={this.state.paymentOnline} onChange={this.paymentOnline} name="Online Payment" />}
														label="Online Payment"
													/>
													<FormControlLabel
														control={<Checkbox checked={this.state.cashOnDelivery} onChange={this.cashOnDelivery} name="Cash On Delivery" />}
														label="Cash On Delivery"
													/>
													<FormControlLabel
														control={<Checkbox checked={this.state.homeDelivery} onChange={this.homeDelivery} name="Home Delivery" />}
														label="Home Delivery"
													/>
													<FormControlLabel
														control={<Checkbox checked={this.state.takeaway} onChange={this.takeaway} name="Takeaway" />}
														label="Takeaway"
													/>

													<FormControlLabel
														control={<Checkbox checked={this.state.forceClose} onChange={this.forceClose} name="Outlet Force Close" />}
														label="Outlet Force Close"
													/>
												</FormGroup>
											</div>
										</div>
										{/* </div> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</>
		);
	}
}

export default connect(mapStateToProps)(AppSettings);