import React, { useEffect, useState } from 'react';
import AuctionTab from '../component/c-auctions/auction-tab';
import { getActiveFoods } from '../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

function Auction() {
    const [data, setData] = useState()
    const [count, setCount] = useState({
        active: "0",
        expire: "0",
        upcoming: "0",
    })
    useEffect(() => {
        activeFoods();
    }, []);
    const rerenderParentCallback = () => {
        activeFoods();
    }
    // Api call ---------------------------------------
    const activeFoods = async () => {
        store.dispatch(loader(true));
        try {
            const allActiveFoods = await getActiveFoods();
            setData(allActiveFoods.data.data);
            setCount({
                active: allActiveFoods.data.data.activeFoodList.length,
                expire: allActiveFoods.data.data.expireFoodList.length,
                upcoming: allActiveFoods.data.data.upcomingFoodList.length,
            })
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-12">
                    <div className="my-4 d-flex justify-content-between">
                        <div className="d-flex align-items-center justify-content-between notifi-box cursor-pointer"
                        style={{background:'rgb(204, 250, 214)'}}
                        //   onClick={() => this.props.history.push('/user-management/new-outlet')}
                        >
                            <div>
                                <div className="icon-container icon-5"><i className="demo-icon font-20">&#xe809;</i></div>
                            </div>
                            <div className="text-center">
                                <div className="title text-black">{count.active}</div>
                                <div className="sub-title text-black">Active Auction</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between notifi-box" style={{background:'rgb(188, 241, 237)'}}>
                            <div>
                                <div className="icon-container icon-3"><i className="demo-icon icon-add-ons font-20">&#xe803;</i></div>
                            </div>
                            <div className="text-center">
                                <div className="title text-black">000</div>
                                {/* <div className="title text-black">{count.ongoing}</div> */}
                                <div className="sub-title text-black">Ongoing Auction</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between notifi-box" style={{background:'rgb(236, 226, 255)'}}>
                            <div>
                                <div className="icon-container icon-3"><i className="demo-icon icon-add-ons font-20">&#xe803;</i></div>
                            </div>
                            <div className="text-center">
                                <div className="title text-black">{count.upcoming}</div>
                                <div className="sub-title text-black">Up Coming Auction</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between notifi-box" style={{background:'#f9cdcd'}}>
                            <div>
                                <div className="icon-container icon-6"><i className="demo-icon icon-add-ons font-20">&#xe803;</i></div>
                            </div>
                            <div className="text-center">
                                <div className="title text-black">{count.expire}</div>
                                <div className="sub-title text-black">Expired Auction</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 col-12"></div>
            </div>
            <div className="category-container my-4">
                {data ? <AuctionTab data={data} rerenderParentCallback={rerenderParentCallback} /> : <div></div>}

            </div>
        </>
    );
}

export default connect(mapStateToProps)(Auction);