import React from 'react';
import Login from './authentication/login';
import TrackOnMap from './component/track-on-map';
import { Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Dashboard from "./dashboard/dashboard";
import OrderReportPage from './all-report/orderReportPage';
import ReportOverView from './all-report/reportOverView';
import OutletTable from './component/c-outlet/outlet-resturent-table';
import OutletItemTable from './component/c-outlet/outlet-item';
import AddOnProduct from './component/c-outlet/add-on-product-table';
import UserManagement from './usermanagements/user-management';
import OtpVerify from './authentication/otp-verify';
import ProtectedRoute from './component/protected';
import Notification from './notificatons/notification';
import Category from './category/category';
import Subscription from './subscription/subscription';
import Auction from './Auctions/auction';
import AuctionBitDetails from './Auctions/auction-bit-details';
import Reference from './reference/reference';
import UserAddNew from './usermanagements/add-user';
import UserDetails from './usermanagements/user-details';
import OutletAddNew from './usermanagements/new-outlet';
import productInfo from './outlet/product-info';
import OffersPage from './discount/offers';
import Settings from './settings/settings';
import { ToastContainer } from 'react-toastify';
import { Hourglass } from 'react-loader-spinner';
import 'react-toastify/dist/ReactToastify.css';
import AdminContainer from './common/admin-container';
import Orders from './orders/orders';
import userOrderDitails from './orders/user-order-details-page';
import AuctionSlot from './Auctions/auction-slot';
import { useSelector } from 'react-redux';
import DeleteUser from './usermanagements/deleteuser';

function App(props) {
  const token = localStorage.getItem('token');
  const load = useSelector((state) => state.loaderReducer.loaderDisplay)
  return (
    <>
      {load ?
        <div className="loader-container">
          <Hourglass
            // visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#306cce', '#72a1ed']}
          />
        </div>
        : <div></div>
      }
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {!token ? <Switch>
          <Route path="/" component={Login} exact={true} />
          <Route path='/delete_user' component={DeleteUser} exact={true} />
          <Route path="/login" component={Login} exact={true} />
          <Route path="/otp-verify/:key?" component={OtpVerify} exact={true} />
          <Redirect to="/login" component={Login} />
        </Switch> :
          <>
            <AdminContainer>
              <Switch>
                <ProtectedRoute path="/track-on-map" component={TrackOnMap} exact={true} />
                <ProtectedRoute exact activeClassName='is-active' path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact activeClassName='is-active' path="/report/orderReportPage" component={OrderReportPage} />
                <ProtectedRoute exact activeClassName='is-active' path="/report/over-view" component={ReportOverView} />
                <ProtectedRoute exact activeClassName='is-active' path="/users-management" component={UserManagement} />
                <ProtectedRoute exact activeClassName='is-active' path="/users-management/add-user/:userId?" component={UserAddNew} />
                <ProtectedRoute exact activeClassName='is-active' path="/users-management/user-details/:userId?" component={UserDetails} />
                <ProtectedRoute exact activeClassName='is-active' path="/outlets/new-outlet/:ownerId?" component={OutletAddNew} />
                <ProtectedRoute exact activeClassName='is-active' path="/outlets" component={OutletTable} />
                <ProtectedRoute exact activeClassName='is-active' path="/outlets/products/:outletId?" component={OutletItemTable} />
                <ProtectedRoute exact activeClassName='is-active' path="/outlets/add-on-product-table/:productId?" component={AddOnProduct} />
                <ProtectedRoute exact activeClassName='is-active' path="/outlets/product-info/:outletId/:productId?" component={productInfo} />
                <ProtectedRoute exact activeClassName='is-active' path="/orders" component={Orders} />
                <ProtectedRoute exact activeClassName='is-active' path="/orders/user-order-ditails/:orderId?" component={userOrderDitails} />
                <ProtectedRoute exact activeClassName='is-active' path="/category" component={Category} />
                <ProtectedRoute exact activeClassName='is-active' path="/subscription" component={Subscription} />
                <ProtectedRoute exact activeClassName='is-active' path="/notification" component={Notification} />
                <ProtectedRoute exact activeClassName='is-active' path="/auction-slot" component={AuctionSlot} />
                <ProtectedRoute exact activeClassName='is-active' path="/auction" component={Auction} />
                <ProtectedRoute exact activeClassName='is-active' path="/auction/auction-bid-details/:slotBookId?" component={AuctionBitDetails} />
                <ProtectedRoute exact activeClassName='is-active' path="/reference" component={Reference} />
                <ProtectedRoute exact activeClassName='is-active' path="/offers" component={OffersPage} />
                <ProtectedRoute exact activeClassName='is-active' path="/settings" component={Settings} />
                <Redirect to="/dashboard" component={Dashboard} />
              </Switch>
            </AdminContainer>
          </>}

      </div>
    </>
  );
}



export default App;
