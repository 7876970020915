import React, { useState, useEffect } from 'react';
import ActionButton from '../component/action-button';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import Carousel from 'react-bootstrap/Carousel';
import { Modal } from "react-bootstrap";
import { getAllSettings, updateAllSettings, updateDashboardAd, uploadImage } from '../api/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { loader } from '../Actions/index';
import { Switch } from '@material-ui/core';

const AppSettings = () => {
    const dispatch = useDispatch();
    const [isSubmitDImg, setIsSubmitDImg] = useState(false);
    const [isSubmitExtra, setIsSubmitExtra] = useState(false);
    const [isSubmitTopImg, setIsSubmitTopImg] = useState(false);
    const [settingsAll, setSettingsAll] = useState([]);
    const [allDashImg, setAllDashImg] = useState([]);
    const [adDashboard, setAdDashboard] = useState(null);
    const [dashImgTitle, setDashImgTitle] = useState("");
    const [dashImgTxt, setDashImgTxt] = useState("");
    const [dashImgIsActive, setDashImgIsActive] = useState(true);
    const [adExtra, setAdExtra] = useState(null);
    const [adImg, setAdImg] = useState(null);
    const [adTop, setAdTop] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        allSettings();
    }, []);

    const handleModalclose = () => {
        setModalShow(!modalShow);
        setAllDashImg(settingsAll.dashboardAd);
    };

    const adImageChange = (event, imgType) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (imgType === "dashboardImg") {
                setAdDashboard(URL.createObjectURL(img));
                setAdImg(img);
                if (isSubmitDImg) valid("dashboardImg");
            }
            else if (imgType === "extrAd") {
                setAdExtra(URL.createObjectURL(img));
                setAdImg(img);
                if (isSubmitExtra) valid("extrAd");
            }
            else if (imgType === "topImg") {
                setAdTop(URL.createObjectURL(img));
                setAdImg(img);
                if (isSubmitTopImg) valid("topImg");
            }
        }
    };

    const handleChangeTitle = (e) => {
        setDashImgTitle(e.target.value);
        if (isSubmitDImg) valid("dashboardImg");
    };

    const handleChangeTxt = (e) => {
        setDashImgTxt(e.target.value);
        if (isSubmitDImg) valid("dashboardImg");
    };

    const handleChangeStatus = (e) => {
        setDashImgIsActive(e.target.value === 'true');
        if (isSubmitDImg) valid("dashboardImg");
    };

    const valid = (type) => {
        let checked = true;
        if (type === "dashboardImg") {
            if (adDashboard === null) checked = false;
            if (dashImgTitle === "") checked = false;
            if (dashImgTxt === "") checked = false;
            if (dashImgIsActive === null) checked = false;
        }
        if (type === "extrAd") {
            if (adExtra === null) checked = false;
        }
        if (type === "topImg") {
            if (adTop === null) checked = false;
        }
        return checked;
    };

    const submitAdImg = (type) => {
        if (type === "dashboardImg") {
            setIsSubmitDImg(true);
            if (valid("dashboardImg")) {
                uploadAdImage("dashboardImg");
            }
        }
        if (type === "extrAd") {
            setIsSubmitExtra(true);
            if (valid("extrAd")) {
                uploadAdImage("extrAd");
            }
        }
        if (type === "topImg") {
            setIsSubmitTopImg(true);
            if (valid("topImg")) {
                uploadAdImage("topImg");
            }
        }
    };

    const submitBtnReset = (type) => {
        if (type === "dashboardImg") {
            setIsSubmitDImg(false);
            setAdDashboard(null);
            setDashImgTitle("");
            setDashImgTxt("");
            setDashImgIsActive(true);
        }
        if (type === "extrAd") {
            setIsSubmitExtra(false);
            setAdExtra(null);
            if (adExtra === null) {
                document.getElementById("file2").value = "";
            }
        }
        if (type === "topImg") {
            setIsSubmitTopImg(false);
            setAdTop(null);
        }
    };

    const deleteDashImg = (e) => {
        if (window.confirm("Are you sure you want to delete this dashboard ad image?")) {
            setAllDashImg(allDashImg.filter(item => item._id !== e._id));
        }
    };

    const changeDashImgStatus = (item) => {
        if (window.confirm("Are you sure you want to change the status of this dashboard ad image?")) {
            const updatedDashImg = allDashImg.map(img => {
                if (img._id === item._id) {
                    return { ...img, isActive: !img.isActive };
                }
                return img;
            });
            setAllDashImg(updatedDashImg);
        }
    };

    const modalDashImgSet = () => {
        changeDashboardAd(allDashImg);
        setModalShow(false);
    };

    const allSettings = async () => {
        dispatch(loader(true));
        try {
            const settings = await getAllSettings();
            setSettingsAll(settings.data.data);
            setAllDashImg(settings.data.data.dashboardAd);
            dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            dispatch(loader(false));
        }
    };

    const uploadAdImage = async (type) => {
        dispatch(loader(true));
        try {
            const loadImg = await uploadImage(adImg, "ad");
            if (type === "dashboardImg") {
                let imgData = settingsAll.dashboardAd;
                const data = [
                    {
                        "title": dashImgTitle,
                        "text": dashImgTxt,
                        "imageUrl": loadImg.data.data.location,
                        isActive: dashImgIsActive
                    },
                    ...imgData
                ];
                changeDashboardAd(data);
            }
            if (type === "extrAd") {
                const data = {
                    "autoSubmit": settingsAll.autoSubmit,
                    "extraAd": loadImg.data.data.location
                };
                updateSettings(data);
            }
            if (type === "topImg") {
                const data = {
                    "autoSubmit": settingsAll.autoSubmit,
                    "topImage": loadImg.data.data.location
                };
                updateSettings(data);
            }
        } catch (ex) {
            dispatch(loader(false));
            console.log(ex);
            ToastError(ex.message);
        }
    };

    const changeDashboardAd = async (data) => {
        dispatch(loader(true));
        try {
            const settings = await updateDashboardAd(data);
            await allSettings();
            submitBtnReset("dashboardImg");
            dispatch(loader(false));
            ToastSuccess(settings.data.message);
        } catch (ex) {
            console.log(ex);
            dispatch(loader(false));
        }
    };

    const updateSettings = async (data) => {
        dispatch(loader(true));
        try {
            await updateAllSettings(data);
            await allSettings();
            submitBtnReset("extrAd");
            submitBtnReset("topImg");
            dispatch(loader(false));
            ToastSuccess("Settings Updated");
        } catch (ex) {
            console.log(ex);
            dispatch(loader(false));
        }
    };

    if (settingsAll.length === 0) return null;

    return (
        <div className="container-fluid">
            <div className="my-3">
                <div className="row">
                    <div className="col-sm-1" />
                    <div className='col-sm-5'>
                        <div className="category-container mt-1">
                            <div style={{ display: 'flex', minWidth: '100%', justifyContent: 'space-between' }}>
                                <h5 className="text-secondary">Dashboard Image</h5>
                                <span onClick={() => setModalShow(true)} style={{ cursor: 'pointer', color: 'red' }}>
                                    View All
                                </span>
                            </div>
                            <div className="ad-container">
                                <Carousel>
                                    {settingsAll.dashboardAd.map(item => (
                                        <Carousel.Item interval={1500} key={item._id}>
                                            <img
                                                className="d-block w-100"
                                                style={{ height: "130px" }}
                                                src={item.imageUrl}
                                                alt="logo"
                                            />
                                            <div className="ad-caption" onClick={() => setModalShow(true)}>
                                                <div className="carousel-link">
                                                    <span>View all</span>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <div className="d-flex justify-content-sm-between p-2">
                                    <div>
                                        <label className="upload-image-container cursor-pointer mr-2" htmlFor="file1">
                                            <img
                                                src={adDashboard}
                                                alt=""
                                                style={adDashboard === null && isSubmitDImg ? { border: "1px solid red" } : {}}
                                                className="upload-image"
                                            />
                                            <i
                                                style={adDashboard !== null ? { color: "transparent" } : {}}
                                                className="demo-icon icon-camera"
                                            >
                                                &#xe807;
                                            </i>
                                            <input
                                                type="file"
                                                id="file1"
                                                accept="image/*"
                                                onChange={(e) => adImageChange(e, "dashboardImg")}
                                            />
                                        </label>
                                    </div>
                                    <div className="text-right">
                                        <div className="form-group mb-2">
                                            <input
                                                onChange={handleChangeTitle}
                                                style={dashImgTitle === "" && isSubmitDImg ? { border: "1px solid red" } : {}}
                                                type="text"
                                                value={dashImgTitle}
                                                className="form-control"
                                                placeholder="Title"
                                            />
                                        </div>
                                        <div className="form-group mb-2">
                                            <input
                                                onChange={handleChangeTxt}
                                                style={dashImgTxt === "" && isSubmitDImg ? { border: "1px solid red" } : {}}
                                                type="text"
                                                value={dashImgTxt}
                                                className="form-control"
                                                placeholder="Text"
                                            />
                                        </div>
                                        <div className="form-group mb-2">
                                            <select
                                                className='form-control'
                                                onChange={handleChangeStatus}
                                                value={dashImgIsActive}
                                            >
                                                <option value={true}>Active</option>
                                                <option value={false}>Inactive</option>
                                            </select>
                                        </div>
                                        <div className="d-flex justify-content-sm-between">
                                            <button
                                                className="btn btn-cancel m-0"
                                                onClick={() => submitBtnReset("dashboardImg")}
                                            >
                                                Reset
                                            </button>
                                            <button
                                                className="btn btn-verify"
                                                onClick={() => submitAdImg("dashboardImg")}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-5'>
                        <div>
                            <div className="category-container mt-1">
                                <h5 className="text-secondary">Extra Ad Image</h5>
                                <div className="ad-container">
                                    <label className="extra-ad-sec cursor-pointer m-0" htmlFor="file2">
                                        <img
                                            src={adExtra !== null ? adExtra : settingsAll.extraAd}
                                            alt=""
                                            style={adExtra === null && isSubmitExtra ? { border: "1px solid red", height: "130px" } : { height: "130px" }}
                                            className="d-block w-100"
                                        />
                                        <input
                                            type="file"
                                            id="file2"
                                            accept="image/*"
                                            onChange={(e) => adImageChange(e, "extrAd")}
                                        />
                                    </label>
                                    {adExtra !== null && (
                                        <div className="text-right p-2">
                                            <button
                                                type="reset"
                                                className="btn btn-cancel m-0 mr-2"
                                                onClick={() => submitBtnReset("extrAd")}
                                            >
                                                Reset
                                            </button>
                                            <button
                                                className="btn btn-verify"
                                                onClick={() => submitAdImg("extrAd")}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="category-container mt-1">
                            <h5 className="text-secondary">Home Page Top Image</h5>
                            <div className="ad-container">
                                <label className="extra-ad-sec cursor-pointer m-0" htmlFor="file3">
                                    <img
                                        src={adTop !== null ? adTop : settingsAll.topImage}
                                        alt=""
                                        style={adTop === null && isSubmitTopImg ? { border: "1px solid red", height: "130px" } : { height: "130px" }}
                                        className="d-block w-100"
                                    />
                                    <input
                                        type="file"
                                        id="file3"
                                        accept="image/*"
                                        onChange={(e) => adImageChange(e, "topImg")}
                                    />
                                </label>
                                {adTop !== null && (
                                    <div className="text-right p-2">
                                        <button
                                            type="reset"
                                            className="btn btn-cancel m-0 mr-2"
                                            onClick={() => submitBtnReset("topImg")}
                                        >
                                            Reset
                                        </button>
                                        <button
                                            className="btn btn-verify"
                                            onClick={() => submitAdImg("topImg")}
                                        >
                                            Add
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Dashboard image Modal */}
                <Modal
                    show={modalShow}
                    onHide={handleModalclose}
                    backdrop="static"
                    size="m"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Dashboard Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="setting-img-box">
                                {allDashImg.map((m) => (
                                    <div
                                        key={m._id}
                                        className="mb-3"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: "100%",
                                            height: "120px"
                                        }}
                                    >
                                        <img
                                            className="card-img"
                                            style={{ width: '85%' }}
                                            src={m.imageUrl}
                                            alt="Card logo"
                                        />
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div onClick={() => deleteDashImg(m)}>
                                                <ActionButton>
                                                    <i className="demo-icon icon-delete">&#xe80e;</i>
                                                </ActionButton>
                                            </div>
                                            <div>
                                                <Switch
                                                    checked={m.isActive}
                                                    onChange={() => changeDashImgStatus(m)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="text-right">
                                <button
                                    className="btn btn-verify"
                                    onClick={modalDashImgSet}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default AppSettings;