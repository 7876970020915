// import React from 'react';
// import GoogleMapReact from 'google-map-react';
// import RoomIcon from '@material-ui/icons/Room';

// const AnyReactComponent = ({ text }) => <div className='map-icon-container'><RoomIcon color="error" /></div>;

// export default function TrackOnMap(props) {

//     const defaultProps = {
//         center: {
//           lat: 22.5726,
//           lng: 88.3639
//         },
//         zoom: 11
//       };
    
//       return (
//         // Important! Always set the container height explicitly
//         <div style={{ height: '100vh', width: '100%' }}>
//           <GoogleMapReact
//             bootstrapURLKeys={{ key: "" }}
//             defaultCenter={defaultProps.center}
//             defaultZoom={defaultProps.zoom}
//           >

//             <AnyReactComponent
//               lat={22.623057003}
//               lng={ 88.46417389612154}
//               text="My Marker"
//             />
//             <AnyReactComponent
//               lat={22.623057002983742}
//               lng={ 88.46417389612154}
//               text="My Marker"
//             />
//             <AnyReactComponent
//               lat={22.623057002983742}
//               lng={ 88.46417389612154}
//               text="My Marker"
//             />
//             <AnyReactComponent
//               lat={22.623057002983742}
//               lng={ 88.46417389612154}
//               text="My Marker"
//             />
//             <AnyReactComponent
//               lat={22.623057002983742}
//               lng={ 88.46417389612154}
//               text="My Marker"
//             />
//           </GoogleMapReact>
//         </div>
//       );
// }


import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

const AnyReactComponent = ({ text }) => <div className='map-icon-container'><RoomIcon color="error" /></div>;

export default function TrackOnMap(props) {
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     setIsLoading(false);
  //     window.open('http://135.181.45.0:3150/map', '_blank');
  //   }, 1.5*1000);
  //   return () => clearTimeout(delay);
  // }, []);

  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {isLoading ? (
        <div className="loading-box">
          <p><h1>Something went wrong.
            <br/>
            Please try again later...</h1></p>
        </div>
      ) : null}
    </div>
  );
}

