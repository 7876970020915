import React, { useEffect, useState } from 'react';
import { subscriptionUsers, getAllSettings } from '../api/apiService';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { loader } from '../Actions';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

export default function Subscription() {
  const [subUser, setSubUser] = useState();
  const [allSubPlan, setAllSubPlan] = useState([]);
  const [selectSubPlan, setSelectSubPlan] = useState("0");
  const dispatch = useDispatch();
  const getDate = (pagNo, searchText, planNo) => {
    var today_datetime = new Date();
    let today_date = moment.utc(today_datetime).format("YYYY-MM-DD");
    var last = (today_datetime - 15 * 24 * 60 * 60 * 1000); // 15 Days ago date
    var ago_date = moment.utc(last).format("YYYY-MM-DD"); // 15 Days ago date format
    allSubscriptionUsers(ago_date, today_date, pagNo, searchText, planNo) // function call
  }
  /*
  * C
  * Bind
  */
  const convertDate = (date) => {
    return moment.utc(date).local().format("YYYY-MM-DD");
    // return date
  }
  const endSubscription = (date, noOfDay) => {
    return moment.utc(moment(date).add(noOfDay, 'days')).local().format("YYYY-MM-DD");
    // return date
  }
  const activeSubscription = (date, noOfDay) => {
    const lastDate = moment.utc(moment(date).add(noOfDay, 'days')).local();
    if (lastDate > moment.utc(new Date()).local()) {
      return true;
    } else {
      return false;
    }
    // return date
  }

  /*
  * Function
  * Bind
  */

  useEffect(() => {
    getDate(1, null, null);
    allSettings();
  }, [])

  const userPageFunc = (m) => {
    // setNoPage(m.selected + 1)
    //getDate(m.selected + 1);
    allSubscriptionUsers(null, null, m.selected + 1, null, selectSubPlan === '0' ? null : selectSubPlan);
  }

  const handleChangePlanNo = (event) => {
    setSelectSubPlan(event.target.value);
    if (event.target.value === "0") {
      getDate(1, null, null)
    } else {
      getDate(1, null, event.target.value)
    }
  }

  const handlePlanSearch = (event) => {
    //getDate(1, event.target.value, null)
    allSubscriptionUsers(null, null, 1, event.target.value, selectSubPlan === '0' ? null : selectSubPlan);
  }
  /*
  * Api
  * Subscriptaion
  * All Users
  */

  const allSubscriptionUsers = async (sDate, eDate, pagNo, searchText, planNo) => {
    dispatch(loader(true));
    try {
      const subs = await subscriptionUsers(sDate, eDate, pagNo, searchText, planNo);
      setSubUser(subs.data);
      dispatch(loader(false));
    } catch (ex) {
      console.log(ex)
      dispatch(loader(false));
    }
  }


  /* 
  * Api
  * Get All Settings
  */
  const allSettings = async () => {
    try {
      const settings = await getAllSettings();
      setAllSubPlan(settings.data.data.subscriptionPlan);
    } catch (ex) {
      console.log(ex);
    }
  }
  return (
    <>
      <div className="mt-4 d-sm-flex justify-content-between">
        <div className="d-flex align-items-center justify-content-between notifi-box "
          style={{ overflow: "hidden", background: "rgb(211, 215, 247)" }}
        // onClick={() => history.push('/users-management/add-user')}
        >

          <div >
            <div className="title text-primary">₹{subUser?.totalAmount?.totalAmount}</div>
            <div className="sub-title">TotalSubscriptions {subUser?.totalAmount?.TotalSubscriptions}</div>
            <div className="sub-title">{`Total Users ${subUser?.totalAmount?.noOfUsers}`}</div>
          </div>
        </div>
        {subUser && subUser.totalData.map(item => {
          return (
            <div key={item._id} className="d-flex align-items-center justify-content-between notifi-box "
              style={{ overflow: "hidden", background: 'rgb(188, 241, 237)' }}
            // onClick={() => history.push('/users-management/add-user')}
            >

              <div>
                <div className="title text-primary">₹{item.amount}</div>
                <div className="sub-title">{`For ${item._id} Days`}</div>
                <div className="sub-title">{`Subscriptions ${item.records}`}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='category-container my-4'>
        <div className="table-search">
          <div className="search-container w-75">
            <div className='d-flex justify-content-between align-item-center'>
              <select
                onChange={(e) => handleChangePlanNo(e)}
                className="form-control mt-2 mr-2" >
                <option value='0' >All</option>
                {allSubPlan.map(item => {
                  return (
                    <option key={item._id} value={item.planNo} >{item.title}</option>
                  );
                })}
              </select>
              <input
                onChange={(e) => handlePlanSearch(e)}
                // value={this.state.outletSearch} 
                type="search" placeholder="Search.." name="search" autoFocus />

            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Image</th>
                <th>Users Name</th>
                <th>Start Date</th>
                <th style={{ width: "26%" }}>Title</th>
                <th>Price</th>
                <th>End Date</th>
                <th>Current Status</th>
              </tr>
            </thead>
            <tbody>
              {subUser && subUser.data.map(item => {
                return (
                  <tr key={item._id} style={{ background: item.user.isDelete ? '#e8ebeb' : '' }}>
                    <td>
                      <img src={item.user.image ? item.image : "/assate/images/profile.png"} className="img-fluid user-profile" alt="hungry top" />
                    </td>
                    <td>
                      <Link to={`/users-management/user-details/${item.user._id}`}>
                        {item.user.isDelete ? (
                          <><i style={{ color: 'red' }}>{item.user.full_name}<p className="font-10 m-0">{item.user.mobile.slice(-10)}</p></i></>
                        ) : (
                          <>{item.user.full_name}<p className="font-10 m-0">{item.user.mobile}</p></>
                        )}
                      </Link>
                    </td>

                    <td>{convertDate(item.startDate)}</td>
                    <td><div>{item.title}</div></td>
                    <td>{item.price}</td>
                    <td>{endSubscription(item.startDate, item.noOfDay)}</td>
                    <td className="text-center">{activeSubscription(item.startDate, item.noOfDay) ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Deactive</span>}</td>
                  </tr>
                );
              })}

            </tbody>
          </table>
        </div>
        <div>
          {subUser &&
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              pageCount={subUser.pageInfo.maxPageNo}
              onPageChange={userPageFunc}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          }
        </div>
      </div>
    </>
  )
}
