import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import DeliverySettings from './DeliverySettings';
import OrderCancelReason from './OrderCancelReason';

const OrderSettings = () => {
    const [isActive, setIsActive] = useState(0);
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setIsActive(newValue);
        setValue(newValue);
    };

    return (
        <div className="mt-4">
            <div className="px-4">
                <AppBar position="static" color="default">
                    <button className={isActive === 0 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(0)}>Delivery Settings</button>
                    <button className={isActive === 1 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(1)}> Order Cancel Reason</button>
                </AppBar>
            </div>
            <div>
                {value === 0 ? <DeliverySettings /> : <OrderCancelReason />}
            </div>
        </div>
    );
};

export default OrderSettings;
