import React, { Component } from 'react';
import AllOrderDetailsTab from '../component/c-orders/all-order-details-tab';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countOrder: {}
        }
    }
    orderCunt = (count) => {
        this.setState({countOrder:count})
    }
    render() {
        return (
            <div className="my-4">
                <div className="d-sm-flex justify-content-between">
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#fff9db"}}>
                        <div>
                            <i className="demo-icon icon-add font-25"  style={{ color: "#e7df7d"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>{this.state.countOrder.countAll}</div>
                            <div className="sub-title" style={{color:'#000'}}>Total Orders</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#cafcf5"}}>
                        <div>
                            <i className="demo-icon icon-user font-25" style={{ color: "#1df3d5"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>{this.state.countOrder.countCompleted}</div>
                            <div className="sub-title" style={{color:'#000'}}>Completed</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#dffcc2"}}>
                        <div>
                            <i className="demo-icon icon-owner font-25" style={{ color: "#95f534"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>{this.state.countOrder.countOutForDelivery}</div>
                            <div className="sub-title" style={{color:'#000'}}>Out for delivery</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#c3bcf9"}}>
                        <div>
                            <i className="demo-icon icon-owner font-25" style={{ color: "#4028f5"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>000</div>
                            {/* <div className="title" style={{color:'#000'}}>{this.state.countOrder.countReadyForPickup}</div> */}
                            <div className="sub-title" style={{color:'#000'}}>Ready for Pickup</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#e7f5b0"}}>
                        <div>
                            <i className="demo-icon icon-owner font-25" style={{ color: "#c6f50e"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>000</div>
                            {/* <div className="title" style={{color:'#000'}}>{this.state.countOrder.countPreparing}</div> */}
                            <div className="sub-title" style={{color:'#000'}}>Preparing</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#f9d8a5"}}>
                        <div>
                            <i className="demo-icon icon-manager font-25" style={{ color: "#fc9a01"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>{this.state.countOrder.countOutletCanceled}</div>
                            <div className="sub-title" style={{color:'#000'}}>Outlet Canceled</div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between notifi-box"  style={{ background: "#fdc5c5"}}>
                        <div>
                            <i className="demo-icon icon-manager font-25" style={{ color: "#fa0505"}}>&#xe81b;</i></div>
                        <div className="text-center">
                            <div className="title" style={{color:'#000'}}>{this.state.countOrder.countCanceled}</div>
                            <div className="sub-title" style={{color:'#000'}}>Canceled</div>
                        </div>
                    </div>
                </div>
                <AllOrderDetailsTab
                    orderCunt={this.orderCunt}
                />
            </div>
        );
    }
}

export default Orders;