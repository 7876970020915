import React, { Component } from "react";
import { getUserOrdersDetails, changeOrderStatus } from "../api/apiService";
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { Modal, Overlay } from "react-bootstrap";
import { connect } from "react-redux";
import { loader } from "../Actions/index";
import store from "../store";
import Moment from "react-moment";
import CardOrders from "../component/c-orders/cardOrders";
import { getAllOrderCancelReason } from "../api/apiService";

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay,
  };
};
class userOrderDitails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: null,
      modalShow: false,
      selectedStatus: '',
      showCancelReason: false,
      setReason: '',
      showCancelText: false,
      cancelReason: '',
      allOrderCancelReasons: []
    };
    this.userOrdersDetails();
  }
  /*
   * Modal Hide & Show
   */
  handleModalShowHide = () => {
    this.setState({ modalShow: !this.state.modalShow });
  };
  // Api Call //////////////////
  /*
   * Api
   * Order Details
   */
  userOrdersDetails = async () => {
    store.dispatch(loader(true));
    try {
      const orderDetails = await getUserOrdersDetails(
        this.props.match.params.orderId
      );
      this.setState({
        orderDetails: orderDetails.data.data,
      });
      store.dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      store.dispatch(loader(false));
    }
  };
  /*
   * Api
   * Order status Change
   */
  handleOrderStatus = async (orderId, event) => {
    let status = event.target.value;
    this.setState({ selectedStatus: status });
    if (status === "canceled" || status === "outlet-canceled") {
      this.setState({ showCancelReason: true });
      this.handleAllOrderCancelReasons();
    } else {
      this.setState({ showCancelReason: false });
    }
  };
  handleCancelReasonChange = async (orderId, event) => {
    let reason = event.target.value;
    this.setState({ setReason: reason });
    this.setState({ cancelReason: reason });
    if (reason === 'other') {
      this.setState({ showCancelText: true });
    } else {
      this.setState({ showCancelText: false });
    }
  };
  handleCancelTextChange = async (orderId, event) => {
    let reason = event.target.value;
    this.setState({ cancelReason: reason });
    if (this.state.cancelReason > 0) {

    }
  };
  handleOrderStatusUpdate = async () => {
    // let status = event.target.value;
    if (window.confirm("Orders status change")) {
      try {
        const orderId = this.props.match.params.orderId;
        const cancelReason = this.state.cancelReason;
        const status = this.state.selectedStatus;
        // console.log("DATA: ",this.props.match.params.orderId,this.state.cancelReason, this.state.selectedStatus)
        const orderStatus = await changeOrderStatus(orderId, cancelReason, status);
        console.log(orderStatus.data.data);
        this.setState({ modalShow: false });
        this.setState({ showCancelReason: false });
        this.userOrdersDetails();
        ToastSuccess(orderStatus.data.message);
      } catch (ex) {
        console.log(ex);
        ToastError(ex.message);
      }
    }
  };
  handleAllOrderCancelReasons = async () => {
    const availableForAdmin = true;
    try {
      getAllOrderCancelReason(null, availableForAdmin)
        .then((response) => {
          console.log(response);
          if (response && response.data && response.data.status === 200) {
            this.setState({ allOrderCancelReasons: response.data?.data });
          } else {
            this.setState({ allOrderCancelReasons: [] });
          }
        })
    } catch (error) {
      console.log(error);
      ToastError('Something went wrong, when fetching cancel reasons...')
    }
  }
  render() {
    let i = 0;
    var index = ++i;
    return (
      <>
        {this.state.orderDetails !== null ? (
          <div className="row my-4">
            <div className="col-sm-8">
              <div className="category-container">
                <div className="user-order-container">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="order-name-container"
                      style={{ cursor: 'pointer', color: '#007bff' }}
                      onClick={() => window.location.href = `/users-management/user-details/${this.state.orderDetails.user._id}`}>
                      <span>Order<br />by:</span>
                      <h4 className="family-Courgette">
                        {this.state.orderDetails.user.full_name}
                        <br />
                        ({this.state.orderDetails.user.mobile})
                      </h4>
                    </div>
                    {this.state.orderDetails.friendsOrFamily === true && (
                      <div className="order-name-container" style={{ color: '#b615f3' }}>
                        <span>Order<br />for:</span>
                        <h4 className="family-Courgette">
                          {this.state.orderDetails.receiverName}
                          <br />
                          ({this.state.orderDetails.receiverMobile})
                        </h4>
                      </div>
                    )}
                    {this.state.orderDetails.trackUrl !== null ? (
                      <a
                        href={this.state.orderDetails.trackUrl}
                        target="_blank"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm"
                        >
                          Track order
                        </button>
                      </a>
                    ) : (
                      <></>
                      // <b>No Track</b>
                    )}

                    {/* <a href="#" target="_blank">Track order</a> */}
                  </div>
                  <address>
                    <b>Pickup Address</b> :{" "}
                    {this.state.orderDetails.pickUpAddress.address}
                  </address>
                  <address>
                    <b>Delivery Address</b> :{" "}
                    {this.state.orderDetails.deliveryAddress.address}
                  </address>
                  <div className="d-flex justify-content-between">
                    <div className="w-50 p-2">
                      <span>
                        <b>Date : </b>
                        <Moment format="DD-MM-YY HH:mm">
                          {this.state.orderDetails.createdDate}
                        </Moment>
                      </span>
                      <br />
                      <span>
                        <b>Order ID</b> : {this.state.orderDetails.orderId}
                      </span>
                      <br />
                      <span>
                        <b>Order Type</b> :{" "}
                        {this.state.orderDetails.orderType === 0
                          ? "Normal"
                          : "Auction"}
                      </span>
                      <br />
                      <span>
                        <b>Payment Mode</b> :{" "}
                        {this.state.orderDetails.paymentMode}
                      </span>
                      <br />
                      <span>
                        <b>Payment Status</b> :{" "}
                        <span className="badge badge-success">
                          {this.state.orderDetails.paymentStatus}
                        </span>
                      </span>
                      <br />
                      <span>
                        <b>Delivery Status</b> :{" "}
                        {this.state.orderDetails.deliveryType === "take_away"
                          ? "Take away"
                          : this.state.orderDetails.deliveryStatus}
                      </span>
                      <br />
                      <span>
                        <b>Order Status</b> :{" "}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.handleModalShowHide()}
                          className={`text-capitalize
                                            badge ${this.state.orderDetails
                              .orderStatus === "completed"
                              ? "badge-success"
                              : this.state.orderDetails
                                .orderStatus === "preparing"
                                ? "badge-primary"
                                : this.state.orderDetails
                                  .orderStatus ===
                                  "ready-for-pickup"
                                  ? "badge-info"
                                  : this.state.orderDetails
                                    .orderStatus ===
                                    "out-for-delivery"
                                    ? "badge-secondary"
                                    : this.state.orderDetails
                                      .orderStatus === "submitted"
                                      ? "badge-warning"
                                      : "badge-danger"
                            }`}
                        >
                          {this.state.orderDetails.orderStatus}<i className="fa fa-caret-down" style={{ paddingLeft: '5px' }} />
                        </span>
                        {this.state.orderDetails.orderStatus === 'canceled' || this.state.orderDetails.orderStatus === 'outlet-canceled' && this.state.orderDetails.orderCancelReason && (<>
                          <br />
                          <span className="no-data"><b>Cancel Reason</b> :{this.state.orderDetails.orderCancelReason}</span>
                        </>)}
                        {/* <button
                          onClick={() => this.handleModalShowHide()}
                          type="button"
                          className="btn btn-link"
                        >
                          Change
                        </button> */}
                      </span>
                    </div>
                    <div className="w-50">
                      <span>
                        <b>Tax</b> : {this.state.orderDetails.tax.toFixed(2)}
                      </span>
                      <br />
                      <span>
                        <b>Discount</b> : {this.state.orderDetails.discount.toFixed(2)}
                      </span>
                      <br />
                      <span>
                        <b>Delivery Charge</b> :{" "}
                        {this.state.orderDetails.deliveryCharge.toFixed(2)}
                      </span>
                      <br />
                      <span>
                        <b>Packaging Charge</b> :{" "}
                        {this.state.orderDetails.packagingCharge.toFixed(2)}
                      </span>
                      <br />
                      <span>
                        <b>Total Amount</b> :{" "}
                        <kbd>₹ {this.state.orderDetails.orderAmount.toFixed(2)}</kbd>
                      </span>
                    </div>
                    <div className="pr-4">
                      <h1 className="m-0 text-center">₹</h1>
                      <h1 className="m-0 text-center family-Courgette">
                        {this.state.orderDetails.orderAmount.toFixed(2)}
                      </h1>
                    </div>
                  </div>
                  {/* Progress Status Table */}
                  {this.state.orderDetails.progressStatus && (<>
                    <div className="my-sm-4">
                      <h4 className="family-Courgette">Progress Status :</h4>
                      <div className="details">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Stage</th>
                              <th>Time</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(this.state.orderDetails.progressStatus).map((key, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{this.state.orderDetails.progressStatus[key].typeText}</td>
                                <td>
                                  <Moment format="DD-MM-YY HH:mm">
                                    {this.state.orderDetails.progressStatus[key].time}
                                  </Moment>
                                </td>
                                <td>{this.state.orderDetails.progressStatus[key].state}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>)}
                  <div className="my-sm-4">
                    <h4 className="family-Courgette">Outlets :</h4>
                    <div className="details">
                      <div className="d-flex">
                        <div className="food-img-sec">
                          <img
                            src={this.state.orderDetails.outlet.outlet_images}
                            className="addon-product-table-img"
                            alt=""
                          />
                        </div>
                        <div className="w-100">
                          <h4
                            style={{ cursor: 'pointer', color: '#007bff' }}
                            onClick={() => window.location.href = `/outlets/products/${this.state.orderDetails.outlet._id}`}>{this.state.orderDetails.outlet.outlet_name}</h4>
                          <div className="d-flex justify-content-between mb-2">
                            <div className="w-50">
                              <span>
                                <b>Phone no</b> :{" "}
                                {this.state.orderDetails.outlet.phone}
                              </span>
                              <br />
                              <span>
                                <b>Cost For Two</b> :{" "}
                                <span className="badge badge-dark">
                                  {this.state.orderDetails.outlet.cost_for_two}
                                </span>
                              </span>
                            </div>
                            <div className="w-50">
                              <span>
                                <b>Preparation Time</b> :{" "}
                                {
                                  this.state.orderDetails.outlet
                                    .food_preparation_time
                                }
                              </span>
                              <br />
                              <span>
                                <b>Outlet</b> :{" "}
                                <span
                                  className={`badge ${this.state.orderDetails.outlet.isActive
                                    ? "badge-success"
                                    : "badge-danger"
                                    }`}
                                >
                                  {this.state.orderDetails.outlet.isActive
                                    ? "Open"
                                    : "Close"}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              {this.state.orderDetails.complementary ? (
                <div className="category-container">
                  <b>Complementary Items:</b>
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ color: 'rgb(49, 124, 120)', fontSize: '16px' }}><b>{this.state.orderDetails.complementary.name}</b></td>
                        <td>
                          <span className="free-complementary">
                            {this.state.orderDetails.complementary.price.toFixed(2)}
                          </span>&nbsp;&nbsp;
                          <span style={{ color: 'rgb(49, 124, 120)' }}>0.00</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
              <br />
              {this.state.orderDetails.foods.map((f) => {
                return <CardOrders key={f._id} title="Foods :" data={f} />;
              })}
              <br />
              <p><b style={{ color: 'rgb(49, 124, 120)' }}>Total Number of Items:&nbsp;&nbsp;</b>{this.state.orderDetails.foods.length}</p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/* modal start */}
        {this.state.orderDetails !== null ? (
          <Modal
            show={this.state.modalShow}
            onHide={this.handleModalShowHide}
            backdrop="static"
            size="sm"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Order Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <i>if the status change once, can't be undone.</i>
              <i>Yet you want to change, please select a status:</i>
              <div>
                <select
                  onChange={(e) =>
                    this.handleOrderStatus(this.state.orderDetails.orderId, e)
                  }
                  className="form-control"
                >
                  <option
                    value={this.state.orderDetails.orderStatus}
                    style={{ display: "none" }}
                  >
                    {this.state.orderDetails.orderStatus}
                  </option>
                  <option value="canceled">Canceled</option>
                  <option value="outlet-canceled">Outlet Canceled</option>
                  <option value="submitted">Submitted</option>
                  <option value="preparing">Preparing</option>
                  <option value="out-for-delivery">Out for delivery</option>
                  <option value="completed">Completed</option>
                </select>
              </div>
              {this.state.showCancelReason && (
                <div className="mt-3">
                  <label>Select Cancellation Reason:</label>
                  <select
                    onChange={(e) => this.handleCancelReasonChange(this.state.orderDetails.orderId, e)}
                    className="form-control"
                    value={this.state.selectedReason}
                  >
                    <option>--Select a reason--</option>
                    {this.state.allOrderCancelReasons.map((reason) => (
                      <option key={reason.cancelReason} value={reason.cancelReason}>
                        {reason.cancelReason}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </select>
                </div>
              )}

              {this.state.showCancelText && (
                <div className="mt-3">
                  <label>Please provide the reason:</label>
                  <textarea
                    onChange={(e) => this.handleCancelTextChange(this.state.orderDetails.orderId, e)}
                    className="form-control"
                    value={this.state.otherReasonText}
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn-verify"
                onClick={this.handleOrderStatusUpdate}
              >Update</button>
            </Modal.Footer>
          </Modal>
          //   <Modal.Header closeButton>
          //     <Modal.Title>Change Order Status</Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body>
          //     <div>
          //       <select
          //         onChange={(e) =>
          //           this.handleOrderStatus(this.state.orderDetails.orderId, e)
          //         }
          //         className="form-control"
          //       >
          //         {this.state.orderDetails.orderStatus === "submitted" ? (
          //           <>
          //             <option value="submitted" disabled selected>
          //               Submitted
          //             </option>
          //             <option value="canceled">Canceled</option>
          //             <option value="outlet-canceled">Outlet Canceled</option>
          //             <option value="preparing">Preparing</option>
          //             <option value="ready-for-pickup">Ready for Pickup</option>
          //             <option value="out-for-delivery">Out for delivery</option>
          //             <option value="completed">Completed</option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus === "preparing" ? (
          //           <>
          //             <option value="preparing" disabled selected>
          //               Preparing
          //             </option>
          //             <option value="ready-for-pickup">Ready for Pickup</option>
          //             <option value="canceled">Canceled</option>
          //             <option value="outlet-canceled">Outlet Canceled</option>
          //             <option value="out-for-delivery">Out for delivery</option>
          //             <option value="completed">Completed</option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus === "Canceled" ? (
          //           <>
          //             <option value="canceled" disabled selected>
          //               Canceled
          //             </option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus ===
          //           "outlet-canceled" ? (
          //           <>
          //             <option value="outlet-canceled" disabled selected>
          //               Outlet Canceled
          //             </option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus ===
          //           "out-for-delivery" ? (
          //           <>
          //             <option value="out-for-delivery" disabled selected>
          //               Out for delivery
          //             </option>
          //             <option value="completed">Completed</option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus ===
          //           "ready-for-pickup" ? (
          //           <>
          //             <option value="ready-for-pickup" disabled selected>
          //               Ready for Pickup
          //             </option>
          //             <option value="out-for-delivery">Out for Delivery</option>
          //             <option value="completed">Completed</option>
          //           </>
          //         ) : this.state.orderDetails.orderStatus === "completed" ? (
          //           <>
          //             <option value="completed" disabled selected>
          //               Completed
          //             </option>
          //           </>
          //         ) : (
          //           <>
          //             <option value="ready-for-pickup">Ready for Pickup</option>
          //             <option value="canceled">Canceled</option>
          //             <option value="outlet-canceled">Outlet Canceled</option>
          //             <option value="preparing">Preparing</option>
          //             <option value="out-for-delivery">Out for delivery</option>
          //             <option value="completed">Completed</option>
          //           </>
          //         )}
          //       </select>
          //     </div>
          //   </Modal.Body>
          // </Modal>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default connect(mapStateToProps)(userOrderDitails);
