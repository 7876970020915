import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import ActionButton from '../component/action-button';
import {
    getAllSettings,
    createSubscription,
    updateSubscription,
    reportSubscriptionPlan
} from '../api/apiService';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';

const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class SubscriptionPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            planNo: "",
            days: "",
            title: "",
            amount: "",
            checkBox: false,
            isSubmitted: false,
            editable: false,
            allSubPlan: []
        }
        this.allSettings();
    }

    /* 
    * All 
    * Form Event handler
    */
    handleDays = (event) => {
        this.setState({
            days: event.target.value
        }, () => {
            console.log(this.state.days);
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    handleTitle = (event) => {
        this.setState({
            title: event.target.value
        }, () => {
            console.log(this.state.title);
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    handleAmount = (event) => {
        this.setState({
            amount: event.target.value
        }, () => {
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    handleCheck = () => {
        this.setState({
            checkBox: !this.state.checkBox
        }, () => {
            console.log(this.state.checkBox);
            return this.state.isSubmitted ? this.valid() : false
        })
    }
    /* 
    * Subscription Plan
    * Status Change
    * Active & Deactive 
    */
    handleChangeStatus = (i) => {
        this.setState({ planNo: i.planNo }, () => {
            let data = {
                "title": i.title,
                "noOfDay": i.noOfDay,
                "price": i.price,
                "isActive": !i.isActive
            }
            this.planUpdate(data);
        })
    }
    /* 
    * Edit 
    * Subscription Plan
    * Button
    */
    handleEditPlan = (i) => {
        this.setState({
            planNo: i.planNo,
            days: i.noOfDay,
            title: i.title,
            amount: i.price,
            checkBox: i.isActive,
            editable: true
        })
    }
    /* 
    * Form Clear Function 
    */
    handleClear = () => {
        this.setState({
            editable: false,
            planNo: "",
            days: "",
            title: "",
            amount: "",
            checkBox: false,
            isSubmitted: false
        })
    }

    /* 
    * Form 
    * Validation  
    */
    valid = () => {
        let checked = true;
        if (this.state.days.length < 1) {
            checked = false;
        }
        if (this.state.title.length < 1) {
            checked = false;
        }
        if (this.state.amount.length < 1) {
            checked = false;
        }
        return checked;
    }

    /* 
    * Api
    * Get All Settings
    */
    allSettings = async () => {
        store.dispatch(loader(true));
        try {
            const settings = await getAllSettings();
            this.setState({ allSubPlan: settings.data.data.subscriptionPlan });
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    /* 
    * Api 
    * Create
    * SubsCription Plan
    */
    subsPlanCreate = async () => {
        let data = {
            "title": this.state.title,
            "noOfDay": this.state.days,
            "price": this.state.amount,
            "isActive": this.state.checkBox
        }
        try {
            const plan = await createSubscription(data);
            this.setState({
                planNo: "",
                days: "",
                title: "",
                amount: "",
                checkBox: false,
                editable: false,
                isSubmitted: false
            });
            this.allSettings();
            ToastSuccess(plan.data.message)
        } catch (error) {
            ToastError(error.response.data.message)
        }
    }
    /* 
    * Api 
    * Update
    * SubsCription Plan
    */
    planUpdate = async (data) => {
        try {
            await updateSubscription(this.state.planNo, data);
            this.setState({
                planNo: "",
                days: "",
                title: "",
                amount: "",
                checkBox: false,
                editable: false,
                isSubmitted: false
            });
            this.allSettings();
            ToastSuccess("Plan updated")
        } catch (ex) {
            console.log(ex)
            ToastError(ex.response.data.message);
        }
    }
    /* 
    * Api 
    * Report
    * SubsCription Plan
    */
    subscribeReport = async () => {
        try {
            const report = await reportSubscriptionPlan();
            const reportUrl = report.request.responseURL;
            // console.log(reportUrl);
            var aLink = document.createElement("a");
            aLink.href = reportUrl;
            aLink.click();

        } catch (ex) {
            console.log(ex)
        }
    }
    /*
    * Subscription
    * form Add & Update
    * Button 
    */
    newSubscriptionAdd = () => {
        this.setState({ isSubmitted: true })
        if (this.valid()) {
            if (this.state.editable) {
                let data = {
                    "title": this.state.title,
                    "noOfDay": this.state.days,
                    "price": this.state.amount,
                    "isActive": this.state.checkBox
                }
                this.planUpdate(data);
            } else {
                this.subsPlanCreate();
            }
        }
    }
    render() {
        return (
            <div className='container-fluid'>
                <div className="row my-4">
                    {/* <div className='col-sm-2'/> */}
                    <div className="col-sm-7">
                        <div className="category-container">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="text-secondary mb-4">All subscription plan</h5>
                                {/* <button onClick={()=>this.subscribeReport()} className="btn btn-verify">Report</button> */}
                            </div>
                            {this.state.allSubPlan.map(item => {
                                return (
                                    <div key={item._id} className="subs-box">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <h3 className="m-0 pl-2">₹{item.price}</h3>
                                            </div>
                                            <div className="col-sm-4 d-flex align-items-center justify-content-center">
                                                <div>
                                                    <p className="m-0">{item.noOfDay} Days</p>
                                                    <span className="text-secondary font-12 text-capitalize">{item.title}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-end">
                                                <Switch
                                                    checked={item.isActive}
                                                    onChange={(e) => this.handleChangeStatus(item)}
                                                    name="checkedAdmin"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <div onClick={() => this.handleEditPlan(item)}>
                                                    <ActionButton>
                                                        <i className="demo-icon icon-edit">&#xe80f;</i>
                                                    </ActionButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="category-container">
                            <div className="d-flex justify-content-between">
                                <h5 className="text-secondary ml-2">Create subscription plan</h5>
                                {this.state.editable ? <button onClick={() => { this.handleClear() }}
                                    className="clear-btn">Clear</button> : ""}
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Days</label>
                                    <input
                                        onChange={(e) => this.handleDays(e)}
                                        style={this.state.days < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="number"
                                        value={this.state.days}
                                        className="form-control"
                                        disabled={this.state.editable}
                                        placeholder="Day" />
                                </div>
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Title</label>
                                    <input
                                        onChange={(e) => this.handleTitle(e)}
                                        style={this.state.title < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="text"
                                        value={this.state.title}
                                        className="form-control"
                                        disabled={this.state.editable}
                                        placeholder="Title" />
                                </div>
                            </div>
                            <div className="category-field">
                                <div className="form-group m-0">
                                    <label className="font-12 m-0">Amount</label>
                                    <input
                                        onChange={(e) => this.handleAmount(e)}
                                        style={this.state.amount < 1 && this.state.isSubmitted ? { border: "1px solid red" } : {}}
                                        type="number"
                                        value={this.state.amount}
                                        className="form-control"
                                        placeholder="Amount" />
                                </div>
                                <div className="form-check mt-2">
                                    <label className="form-check-label">
                                        <input
                                            onChange={(e) => this.handleCheck(e)} type="checkbox" className="form-check-input"
                                            value={this.state.checkBox}
                                            checked={this.state.checkBox}
                                        // defaultChecked={this.props.checkBox}
                                        />
                                        <span className="text-secondary">Active subscription plan</span>
                                    </label>
                                </div>
                                <div className="text-right">
                                    <button
                                        onClick={() => this.newSubscriptionAdd()}
                                        className="btn btn-verify mt-2">{this.state.editable ? "Update" : "Add"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(SubscriptionPlan);