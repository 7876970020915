import React, { useState, useEffect } from "react";
import { Checkbox, Switch, Tooltip } from "@material-ui/core";
import { ToastSuccess, ToastError } from "../component/toast-notification";
import { connect } from "react-redux";
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { getAllSettings, updateMaintenanceMode } from '../api/apiService';
import moment from "moment";

const mapStateToProps = (state) => {
  return {
    socketId: state.loaderReducer.socketId,
    loaderDisplay: state.loaderDisplay,
  };
};

const MaintenanceMode = (props) => {
  const [isUpdate, setisUpdate] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [newStartDate, setNewStartDate] = useState('');
  const [newEndDate, setNewEndDate] = useState('');
  const [newStartTime, setNewStartTime] = useState('');
  const [newEndTime, setNewEndTime] = useState('');

  useEffect(() => {
    if (props.socketId !== null) {
      initSocket();
    } else {
      setTimeout(() => {
        if (props.socketId !== null) {
          initSocket();
        }
      }, 5000);
    }
    fetchData();
  }, [props.socketId]);

  const initSocket = () => {
    props.socketId.on("settings", (data) => {
      fetchData();
    });
  };

  const fetchData = () => {
    getAllSettings()
      .then((response) => {
        if (response && response.data && response.data.status === 'success') {
          const startTime = moment(response.data.data.maintenanceModeStartTime);
          const endTime = moment(response.data.data.maintenanceModeEndTime);
          setMaintenanceMode(response.data.data.maintenanceMode);
          if (startTime.isValid() && endTime.isValid()) {
            setStartDate(startTime.format('YYYY-MM-DD'));
            setStartTime(startTime.format('HH:mm'));
            setEndDate(endTime.format('YYYY-MM-DD'));
            setEndTime(endTime.format('HH:mm'));
            setNewStartDate(startTime.format('YYYY-MM-DD'));
            setNewStartTime(startTime.format('HH:mm'));
            setNewEndDate(endTime.format('YYYY-MM-DD'));
            setNewEndTime(endTime.format('HH:mm'));
          } else {
            console.error('Invalid date/time received from the server');
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching settings:', error);
      });
  };
  const handleStatusChange = (e) => {
    e.preventDefault();
    const newMaintenanceMode = !maintenanceMode;
    setMaintenanceMode(newMaintenanceMode);
    const data = { startDate, startTime, endDate, endTime, maintenanceMode: newMaintenanceMode };
    try {
      updateMaintenanceMode(data)
        .then((response) => {
          if (response && response.data && response.data.status === 'success') {
            ToastSuccess(response.data.message);
          }
        })
    } catch (error) {
      console.log('Updating maintenance mode error: ', error);
      ToastError('Something went wrong...');
    } finally {
      setBtnDisable(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { startDate: newStartDate, startTime: newStartTime, endDate: newEndDate, endTime: newEndTime, maintenanceMode };
    try {
      updateMaintenanceMode(data)
        .then((response) => {
          if (response && response.data && response.data.status === 'success') {
            ToastSuccess(response.data.message);
            setisUpdate(false);
          }
        })
    } catch (error) {
      console.log('Updating maintenance mode error: ', error);
      ToastError('Something went wrong...');
    } finally {
      setBtnDisable(false);
    }
  };

  const handleClear = () => {
    setBtnDisable(false);
    setNewStartDate('');
    setNewStartTime('');
    setNewEndDate('');
    setNewEndTime('');
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-sm-8 mt-1">
            <div className={isUpdate ? "col-sm-1 mt-1" : "col-sm-2 mt-1"} />
            <div className="maintenance-container">
              <span className="d-flex justify-content-between align-items-center">
                <h5 className="text-secondary mb-4">Maintenance Mode:</h5>
                <span>
                  <Switch checked={maintenanceMode} onClick={handleStatusChange} />
                  <i className="fas fa-edit btn-verify" onClick={() => setisUpdate(true)} />
                </span>
              </span>
              <div>
                <p>Due to scheduled security update the app will close from {startDate}, {startTime} to {endDate}, {endTime}.
                  <br />
                  The app will reopen after {endDate}, {endTime}
                  <br />
                  Please bear with us.
                  <br />
                  <br />
                  Thank You,
                  <br/>
                  <b>Team HungryTop</b></p>
              </div>
            </div>
          </div>
          {isUpdate && (
            <div className="col-sm-4 mt-1">
              <div className="category-container">
                <div className="d-flex justify-content-between">
                  <h5 className="text-secondary ml-2">Update Scheduled Maintenance</h5>
                  <button className="clear-btn" onClick={handleClear}>Clear</button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="category-field">
                    <div className="form-group">
                      <label>Start Date:</label>
                      <input className="form-control" type="date" value={newStartDate} onChange={(e) => setNewStartDate(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label>Start Time:</label>
                      <input className="form-control" type="time" value={newStartTime} onChange={(e) => setNewStartTime(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label>End Date:</label>
                      <input className="form-control" type="date" value={newEndDate} onChange={(e) => setNewEndDate(e.target.value)} required />
                    </div>
                    <div className="form-group">
                      <label>End Time:</label>
                      <input className="form-control" type="time" value={newEndTime} onChange={(e) => setNewEndTime(e.target.value)} required />
                    </div>
                  </div>
                  <button className={btnDisable ? "btn-disable" : "btn-verify"} type="submit" disabled={btnDisable}>Update</button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(MaintenanceMode);
