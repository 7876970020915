import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import UserOrderDetails from './user-order-details-table';
import OwnerOutletList from './owner-outlet-list-table';
import AuctionDetails from './auction-details-table';
import AccountSection from './account-section';

const UserDetailsTab = (props) => {
    const [isActive, setIsActive] = useState(0);
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setIsActive(newValue);
        setValue(newValue);
    };

    return (
        <div>
            <div className="d-inline-block">
                {props.isOwner === false ?
                    <div>
                        <button className={isActive === 0 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(0)}>Order Details</button>
                        <button className={isActive === 1 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(1)}>Auction Details</button>
                    </div>
                    :
                    <div>
                        <button className={isActive === 0 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(0)}>Outlet List</button>
                        <button className={isActive === 1 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(1)}>Order Details</button>
                        <button className={isActive === 2 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(2)}>Auction Details</button>
                        <button className={isActive === 3 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(3)}>Account</button>
                    </div>}
            </div>
            {/* <div className="d-inline-block float-md-right mt-3">
                <span className="mr-5">See Newer</span>
                <span>See Older</span>
            </div> */}
            <div>
                {props.isOwner === false ?
                    <>
                        {value === 0 ? <UserOrderDetails userId={props.userId} /> : <AuctionDetails userId={props.userId} />}
                    </>
                    :
                    <>
                        {value === 0 ? (
                            <OwnerOutletList data={props.data} />
                        ) : value === 1 ? (
                            <UserOrderDetails userId={props.userId} />
                        ) : value === 2 ? (
                            <AuctionDetails userId={props.userId} />
                        ) : (
                            <AccountSection userAc={props.uDetails} rerenderParentCallback={props.rerenderParentCallback} />
                        )}
                    </>
                }

            </div>
        </div>
    );
}
export default UserDetailsTab;