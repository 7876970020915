import React, { useEffect, useState, useMemo } from 'react';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Link } from 'react-router-dom';
import { getReportOutlet, getReportOutletDaily, getReportOutletMonthly } from '../../api/apiService';
import { useParams } from 'react-router-dom';
import CardOutletReport from './card-outlet-report';
import moment from 'moment';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';



const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}


function OutletReports() {
    var date_today = moment(new Date()).format('YYYY-MM-DD');
    var date_year_month = moment(new Date()).format('YYYY-MM');
    const params = useParams();
    const [dateMonthly, setDateMonthly] = useState(date_year_month);
    const [apiType, setApiType] = useState('all');
    const [btnIndex, setBtnIndex] = useState(0);
    const [cardTitle, setCardTitle] = useState('');
    const [page, setPage] = useState(1);
    const [allReports, setAllReports] = useState({});
    const [allMonth, setAllMonth] = useState([]);
    const [allCount, setAllCount] = useState({});



    useEffect(() => {
        if (params.outletId) {
            if (cardTitle === '') {
                outletDailyOrderReport(params.outletId, date_today, page, apiType);
                outletOrderReport(params.outletId, page, apiType);
            }
            if (btnIndex === 0 && cardTitle === 'Today') {
                outletDailyOrderReport(params.outletId, date_today, page, apiType);
            }
            if ((btnIndex === 1 && cardTitle === 'This Month') || (btnIndex === 2 && cardTitle === 'Monthly')) {
                outletMonthlyOrderReport(params.outletId, dateMonthly, page, apiType);
            }
            if (btnIndex === 3 && cardTitle === 'Total') {
                outletOrderReport(params.outletId, page, apiType);
            }
        }

    }, [btnIndex, apiType, dateMonthly, page]);



    /*
    * Pagination 
    *  
    */
    const handleOrderPageNo = (m) => {
        setPage(m.selected + 1);
    }


    /*
    * Button Group
    * Top 
    */
    const handleBtnGroup = (event, num, txt) => {
        setBtnIndex(num);
        setCardTitle(txt);
        if (txt === 'Monthly') {
            if (event.target.value !== '') {
                setDateMonthly(event.target.value);
            }
        } else {
            setDateMonthly(date_year_month);
        }

    }
    /*
    * Button
    * Sub Tab 
    */
    const handleSubTab = (tabTxt) => {
        setApiType(tabTxt);
    }

    /*
    * Api
    * Outlet Total Orders
    */
    const outletOrderReport = async (orderId, pageNo, type) => {
        store.dispatch(loader(true));
        try {
            const report = await getReportOutlet(orderId, pageNo, type);
            store.dispatch(loader(false));
            if (btnIndex === 3) {
                setAllReports(report.data.data);
                setAllCount(report.data.data.details)
            } else {
                setAllMonth(report.data.data.allMonths);
            }
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }



    /*
    * Api
    * Outlet Monthly Reports
    */
    const outletMonthlyOrderReport = async (orderId, date, pageNo, type) => {
        store.dispatch(loader(true));
        try {
            const report = await getReportOutletMonthly(orderId, date, pageNo, type);
            store.dispatch(loader(false));
            setAllReports(report.data.data);
            setAllCount(report.data.data.details)
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }



    /*
    * Api
    * Outlet Daily Reports
    */
    const outletDailyOrderReport = async (orderId, date, pageNo, type) => {
        var details = [];
        store.dispatch(loader(true));
        try {
            const report = await getReportOutletDaily(orderId, date, pageNo, type)
            store.dispatch(loader(false));
            setAllReports(report.data.data);
            setCardTitle('Today');
            setAllCount(report.data.data.details)
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }



    return (
        <div className='outlet-report-sec p-3'>
            <div className='d-flex align-items-center flex-wrap'>
                {/* Top Button Section */}
                <div className="btn-group mb-4 mr-2">
                    <button type="button"
                        onClick={(ev) => handleBtnGroup(ev, 0, 'Today')}
                        className={` ${btnIndex === 0 ? 'is-active-btn' : 'btn btn-group-outline '}`}>
                        Today
                    </button>
                    <button type="button"
                        onClick={(ev) => handleBtnGroup(ev, 1, 'This Month')}
                        className={` ${btnIndex === 1 ? 'is-active-btn' : 'btn btn-group-outline '}`}>
                        This Month
                    </button>
                    <select
                        className={` ${btnIndex === 2 ? 'is-active-btn' : 'btn btn-group-outline '}`}
                        onChange={(ev) => handleBtnGroup(ev, 2, 'Monthly')}>
                        <option value="" style={{ cursor: 'none' }}>Monthly</option>
                        {allMonth.map(opt => {
                            return <option key={opt._id} value={opt._id}>{opt._id}</option>
                        })}
                    </select>
                    <button type="button"
                        onClick={(ev) => handleBtnGroup(ev, 3, 'Total')}
                        className={` ${btnIndex === 3 ? 'is-active-btn' : 'btn btn-group-outline '}`}>
                        Total
                    </button>
                </div>
                {/* <div className='mb-4'>
                    <input type="search" className='search-input' placeholder="Search.." name="search" autoFocus />
                </div> */}
            </div>
            <div className='outlet-Report-card mb-5'>
                <CardOutletReport
                    title={`${cardTitle} Amount`}
                    // amount={`₹ ${allCount?.outletPayableAmount}`}
                    amount={allCount === null ? 0 : (allCount?.normalAmount + allCount.auctionAmount)}
                    subCount={allCount === null ? 0 : (allCount?.normalCount + allCount.auctionCount)}
                    rightIconColor="#31ce77"
                />
                <CardOutletReport
                    title="Normal Orders"
                    amount={allCount === null ? 0 : allCount?.normalAmount}
                    subCount={allCount === null ? 0 : allCount?.normalCount}
                    rightIconColor="#1cb7b7"
                />
                <CardOutletReport
                    title="Auction Orders"
                    amount={allCount === null ? 0 : allCount?.auctionAmount}
                    subCount={allCount === null ? 0 : allCount?.auctionCount}
                    rightIconColor="#9d95f5"
                />
                <CardOutletReport
                    title="Delivery Charge"
                    amount={allCount === null ? 0 : allCount?.deliveryChargeAmount}
                    rightIconColor="#C780FA"
                />
                <CardOutletReport
                    title="Hungry Top Charge"
                    amount={allCount === null ? 0 : allCount?.HTCharge}
                    rightIconColor="#d6a236"
                />
                <CardOutletReport
                    title="Outlet Payble"
                    amount={allCount === null ? 0 : allCount?.outletPayableAmount}
                    rightIconColor="#7FB77E"
                />
                <CardOutletReport
                    title="Cancel Orders"
                    amount={allCount === null ? 0 : allCount?.cancelAmount}
                    subCount={allCount === null ? 0 : allCount?.cancelCount}
                    rightIconColor="#EC7272"
                />
            </div>


            <div className="outlet-report-sub-tab">

                <div className="btn-group btn-group-sm mb-2">
                    <button type="button"
                        onClick={() => handleSubTab('all')}
                        className={`  ${apiType === 'all' ? 'is-active-btn' : 'btn btn-group-list'}`} >
                        All Order
                    </button>
                    <button type="button"
                        onClick={() => handleSubTab('normal')}
                        className={`  ${apiType === 'normal' ? 'is-active-btn' : 'btn btn-group-list'}`}>
                        Normal
                    </button>
                    <button type="button"
                        onClick={() => handleSubTab('auction')}
                        className={`  ${apiType === 'auction' ? 'is-active-btn' : 'btn btn-group-list'}`}>
                        Action
                    </button>
                </div>
                {allReports.list?.items.length ?
                    <div className='outlet-report-list-container'>
                        {
                            allReports?.list?.items?.map((item, index) => {
                                return (
                                    <div key={index} className='outlet-report-list'>
                                        <h5 className="user-name">{item.user.full_name}</h5>
                                        <div className='row'>
                                            <div className='col-md-6 col-12'>
                                                <h6 className="text-teal"><CalendarTodayOutlinedIcon className='mr-2' />
                                                    <Moment format="DD-MM-YY HH:mm">
                                                        {item.createdDate}
                                                    </Moment>
                                                    <span className="tag ml-2">{item.orderType === 0 ? "Normal" : "Auction"}</span>
                                                </h6>
                                                <span>Price: ₹ {item.orderAmount}</span>
                                            </div>
                                            <div className='col-md-6 col-12'>
                                                <Link to='#'><Link to={`/orders/user-order-ditails/${item.orderId}`} className="text-teal"><span>ID: {item.orderId}</span></Link></Link>
                                                <span>
                                                    Order Status: <span className={`text-capitalize p-1
                                            badge ${item.orderStatus === "completed" ? "success-badge" : item.orderStatus === "preparing" ? "primary-badge" : item.orderStatus === "out-for-delivery" ? "badge-info" : item.orderStatus === "submitted" ? "badge-warning" : "danger-badge"}`}>
                                                        {item.orderStatus}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    :
                    <div>
                        <img src='../../assate/images/no_data_found1.png' className='img-fluid' alt='logo' />
                    </div>
                }
            </div>

            <div className='mt-3'>
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    pageCount={allReports.list?.pageInfo ? allReports.list.pageInfo.maxPageNo : 0}
                    onPageChange={handleOrderPageNo}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(OutletReports);