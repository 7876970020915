import React, { useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import MaintenanceMode from "./MaintenanceMode";

const OrderCancelReason = () => {
  const [isActive, setIsActive] = useState(0);
  const [value, setValue] = useState(0);

  const handleChange = (newValue) => {
    setIsActive(newValue);
    setValue(newValue);
  };
  return (
    <>
      <div className="mt-4">
        <div className="px-4">
          <AppBar position="static" color="default">
            <button className={isActive===0 ? 'tab-panel active' : 'tab-panel'} onClick={()=>handleChange(0)}>Maintenance Mode</button>
            <button className={isActive===1 ? 'tab-panel active' : 'tab-panel'} onClick={()=>handleChange(1)}>Others Settings </button>
          </AppBar>
        </div >
        {value === 0 ? <MaintenanceMode /> : <></>
        }
      </div >
    </>
  );
};

export default OrderCancelReason;
