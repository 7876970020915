import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppSettings from './AppSettings'
import AppImages from './appImages';
import SubscriptionPlan from './subscriptionPlan';
import OrderSettings from './OrderSettings';

const OffersPage = () => {
    // export default function OffersPage(props) {
    const [isActive, setIsActive] = useState(0);
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setIsActive(newValue);
        setValue(newValue);
    };
    return (
        <div className='mt-2'>
            <div className="px-4">
                {/* <AppBar position="static" color="default"> */}

                    <button className={isActive === 0 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(0)}>App Images</button>
                    <button className={isActive === 1 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(1)}>App Settings</button>
                    <button className={isActive === 2 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(2)}>Order Settings</button>
                    <button className={isActive === 3 ? 'tab-panel active' : 'tab-panel'} onClick={() => handleChange(3)}>Subscription Plan</button>
                {/* </AppBar> */}
            </div>
            {value === 0 ? (
                <AppImages />
            ) : value === 1 ? (
                <AppSettings />
            ) : value === 2 ? (
                <OrderSettings />
            ) : (
                <SubscriptionPlan />
            )}
        </div>
    );
}



export default OffersPage