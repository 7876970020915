import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import {Button} from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import SideManu from './sideManu';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function SideDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, true)}
    >
      <div className="d-flex align-items-center justify-content-center" style={{padding:'5px 2px'}}>
        <img src="/assate/images/hungry-logo.svg" alt="hungry top" className="logo1" />
        <span style={{paddingLeft:'5px',fontSize:'10px',color:'#fff'}}>HungryTop<br/>Technologies<br/>Limited</span>
      </div>
      <Divider style={{ margin: '10px 0',backgroundColor: 'white' }} />
      <SideManu/>
    </div>
  );

  return (
    <div className="side-drower">
      <Button onClick={toggleDrawer("left", true)} style={{background:'transparent !important'}}><MenuIcon className='text-white'/></Button>
      {/* <Drawer open={state["left"]} onClose={toggleDrawer("left", false)}>
        {list("left")}
      </Drawer> */}
      <Drawer
  open={state["left"]}
  onClose={toggleDrawer("left", false)}
  classes={{ paper: 'side-drower' }} // Apply class to Drawer
>
  {list("left")}
</Drawer>

    </div>
  );
}
