import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContainer from '../common/auth-container';
import { deleteUser } from '../api/apiService';

class DeleteUser extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fullName: "",
        mobile: "",
        fullNameError: "",
        mobileError: "",
        errorMessage: "",
      };
    }
  
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
  
    validate = () => {
      let isValid = true;
      if (!this.state.fullName) {
        this.setState({ fullNameError: "Full name is required" });
        isValid = false;
      } else {
        this.setState({ fullNameError: "" });
      }
  
      if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.mobile)) {
        this.setState({ mobileError: "Invalid mobile number" });
        isValid = false;
      } else {
        this.setState({ mobileError: "" });
      }
  
      return isValid;
    };
  
    deleteUser = () => {
      // Call the API to delete the user
      deleteUser(this.state.fullName, this.state.mobile)
        .then(response => {
          console.log("User deleted successfully", response);
          toast.success(response.data.message)
          this.setState({ errorMessage: "" });
          // Optionally, redirect or inform the user of success
        })
        .catch(error => {
          // Handle error
          console.error("Failed to delete user", error);
          this.setState({ errorMessage: "Failed to delete user. Please try again." });
        });
    };
  
    handleSubmit = (e) => {
      e.preventDefault();
      if (this.validate()) {
        this.deleteUser();
      }
    };
  
    render() {
      // Render logic remains the same
      return (
        <>
        <AuthContainer>
          <form onSubmit={this.handleSubmit}>
            <p className="error-msg">{this.state.errorMessage}</p>
            <input
              type="text"
              name="fullName"
              className="user-input"
              placeholder="Enter your full name"
              onChange={this.handleChange}
              style={this.state.fullNameError ? { border: "1px solid red" } : {}}
            />
            <p className="error-msg">{this.state.fullNameError}</p>
            <input
              type="number"
              name="mobile"
              className="user-input"
              placeholder="Enter your mobile"
              onChange={this.handleChange}
              style={this.state.mobileError ? { border: "1px solid red" } : {}}
            />
            <p className="error-msg">{this.state.mobileError}</p>
            <button
              className="user-login-btn bg-btn-color"
              type="submit"
              disabled={!this.state.fullName || !/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.mobile)}
              style={this.state.fullName && /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.mobile) ? { backgroundColor: "#D2A33B", color: "#FFFFFF" } : {}}
            >
              Initiate Delete Request
            </button>
          </form>
        </AuthContainer>
        </>
      );
    }
}

export default DeleteUser;
